import React from 'react'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements'

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard as="a" href="https://www.medgpt.net/">
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>MedGPT</ServicesH2>
                    <ServicesP>An AI Doctor that can help you diagnose diseases.</ServicesP>
                </ServicesCard>
                <ServicesCard as="a" href="https://researchcopilot.net/">
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Research Copilot</ServicesH2>
                    <ServicesP>Tailored solutions to meet any research demands.</ServicesP>
                </ServicesCard>
                <ServicesCard as="a" href="https://www.microtrode.com/">
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Bio Sensors</ServicesH2>
                    <ServicesP>Monitor bio-signal with mini wireless devices.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services