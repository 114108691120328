import React from 'react'
import NavbarWithoutTags from '../components/NavbarWithoutTags'
import Footer from '../components/Footer'
import ArticleSection from '../components/ArticleSection'

const ArticlePage = () => {
  return (
    <>
      <NavbarWithoutTags />
      <ArticleSection />
      <Footer />
    </>
  )
}

export default ArticlePage