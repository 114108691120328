import React, {useState} from 'react'
import Video from '../../videos/video2.mp4'
import {ScrollButton} from '../MainButtonElements'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper,ArrowForward, ArrowRight } from './HeroElements'

const HeroSection = () => {
    const[hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

  return (
    <HeroContainer>
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video2/mp4' />
        </HeroBg>
        <HeroContent>
            <HeroH1>
                NeuroPrior AI
            </HeroH1>
            <HeroP>
            We Are a Benefit Company That Provide Free Online Diagnosis of Brain Functions
            </HeroP>
            <HeroBtnWrapper>
                <ScrollButton to="tests" smooth={true} duration={500} spy={true} exact='true' offset={-60} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
                    Start Testing {hover ? <ArrowForward /> : <ArrowRight />}
                </ScrollButton>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection