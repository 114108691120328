import React, { useState, useEffect } from "react"
import EmptyList from '../components/common/EmptyList';
import BlogList from '../components/Home/BlogList';
import Header from '../components/Home/Header';
import SearchBar from '../components/Home/SearchBar';
import { blogList } from '../config/data';
import './style.css';


export default function BlogHomeSection() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const [blogs, setBlogs] = useState(blogList);
    const [searchKey, setSearchKey] = useState('');

    // Search submit
    const handleSearchBar = (e) => {
        e.preventDefault();
        handleSearchResults();
    };

    // Search for blog by title
    const handleSearchResults = () => {
        const allBlogs = blogList;
        const filteredBlogs = allBlogs.filter((blog) =>
            blog.title.toLowerCase().includes(searchKey.toLowerCase().trim())
        );
        setBlogs(filteredBlogs);
    };

    // Clear search and show all blogs
    const handleClearSearch = () => {
        setBlogs(blogList);
        setSearchKey('');
    };





    return (
        <div className="blog-home-main">
            <div className="blog-home-wrap">
                {/* Page Header */}
                <Header />

                {/* Search Bar */}
                <SearchBar
                    value={searchKey}
                    clearSearch={handleClearSearch}
                    formSubmit={handleSearchBar}
                    handleSearchKey={(e) => setSearchKey(e.target.value)}
                />

                {/* Blog List & Empty View */}
                {!blogs.length ? <EmptyList /> : <BlogList blogs={blogs} />}
            </div>
        </div>
    )
}
