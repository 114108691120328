export const papers = [{
    id: 0, // Used in JSX as a key
    name: 'Asynchronous Detection of Erroneous Behaviors in Human-Robot Interaction with EEG: A Comparative Analysis of Machine Learning Models',
    authors: 'Zhezheng Ren, Xuzhe Xia, Yuzhi Tang, Bo Zhao, Chun Pang Wong, Dongsheng Xiao',
    publisher: 'Association for the Advancement of Artificial Intelligence (AAAI), 2023',
    links: '[Paper] [BibTex]',
    imageURL: require('../../images/Articles/paper_aaai.png')
}
// , {
//     id: 1, // Used in JSX as a key
//     name: 'Early Neuropsychiatric Disorder Detection',
//     authors: 'Chun Pang Wong, Sin Mang(Astrid) Tam, Zhezheng Ren, Anna Malikovskaia',
//     publisher: 'Multidisciplinary Undergraduate Research Conference (MURC), 2023',
//     links: '[Paper] [BibTex]',
//     imageURL: require('../../images/Articles/paper_2.png')
// }
];


export const conferences = [{
    id: 0, // Used in JSX as a key
    name: 'Early Neuropsychiatric Disorder Detection: Using DeepFace and Machine Learning to Analyze Abnormal Facial Expressions',
    authors: 'Chun Pang Wong, Sin Mang Tam, Zhezheng Ren, Anna Malikovskaia',
    publisher: 'Multidisciplinary Undergraduate Research Conference (MURC), 2023',
    links: '[Paper] [BibTex]',
    imageURL: require('../../images/Articles/paper_1.png')
}, 
// {
//     id: 1, // Used in JSX as a key
//     name: 'Early Neuropsychiatric Disorder Detection',
//     authors: 'Chun Pang Wong, Sin Mang(Astrid) Tam, Zhezheng Ren, Anna Malikovskaia',
//     publisher: 'Multidisciplinary Undergraduate Research Conference (MURC), 2023',
//     links: '[Paper] [BibTex]',
//     imageURL: require('../../images/Articles/paper_2.png')
// }
];
