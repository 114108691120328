import React, { useEffect } from "react"
import { papers,conferences } from './ArticleData.js';
import './style.css';

export default function ArticleSection() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const listItemsArticles = papers.map(paper =>
        <div className='article-grid'>
            <li className='article-list-li' key={paper.id}>
                <div className='article-imgBx'>
                    <img className='article-img'
                        src={paper.imageURL}
                        alt="pic"
                    />
                </div>
                <p className='article-p'>
                    <b className="article-name">{paper.name}</b>
                    <p className="article-author">{paper.authors}</p>
                    <p className="article-info">{paper.publisher}</p>
                    <p className="article-links">{paper.links}</p>
                </p>
            </li>
        </div>
    );

    const listItemsConference = conferences.map(conference =>
        <div className='article-grid'>
            <li className='article-list-li' key={conference.id}>
                <div className='article-imgBx'>
                    <img className='article-img'
                        src={conference.imageURL}
                        alt="pic"
                    />
                </div>
                <p className='article-p'>
                    <b className="article-name">{conference.name}</b>
                    <p className="article-author">{conference.authors}</p>
                    <p className="article-info">{conference.publisher}</p>
                    <p className="article-links">{conference.links}</p>
                </p>
            </li>
        </div>
    );

    return (
        <div className="article-main-dev">
            <h1 className="article-title-h1">Research Articles</h1>
            <ul className="article-list">{listItemsArticles}</ul>
            <h1 className="article-title-h2">Conference Abstracts</h1>
            <ul className="article-list">{listItemsConference}</ul>
        </div>
    )
}
