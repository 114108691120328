import React, { useEffect } from "react"
import CardGrid from "./CardGrid";
import Card from "./Card";
import './style.css';

const OurTeam = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="main-dev" >
            <h1 className="title-h1">Our Team</h1>
            <CardGrid>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Dongsheng Xiao.jpg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Dongsheng Xiao, M.D., Ph.D.</h5>
                            <h6 className="title-h6">Founder & Chief Executive Officer</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/ZhaoBo3.png')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Bo Zhao, Ph.D.</h5>
                            <h6 className="title-h6">Co-founder & Chief Scientific Officer</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Jamie Boyd, Ph.D., Chief Hardware Officer, co-founder.jpg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Jamie Boyd, Ph.D.</h5>
                            <h6 className="title-h6">Co-founder & Chief Hardware Officer</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Dirk_Haupt.jpg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Dirk Haupt, MSc.</h5>
                            <h6 className="title-h6">Co-founder & Chief Technology Officer</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Wong Chun Pang.jpg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Chun Pang Wong</h5>
                            <h6 className="title-h6">Co-founder & Principal Project Manager</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Zhezheng Ren.jpeg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Zhezheng Ren</h5>
                            <h6 className="title-h6">Co-founder & Principal Hardware Engineer</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Xuzhe Xia.jpeg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Xuzhe Xia</h5>
                            <h6 className="title-h6">Co-founder & Principal Software Engineer</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Mindy Somin Lee.jpg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Mindy Somin Lee</h5>
                            <h6 className="title-h6">Co-founder & Principal Robotic Engineer</h6>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="content">
                        <div className="imgBx">
                            <img className="member-img" src={require('../../images/Members/Paul Tang.jpg')} alt="pic" />
                        </div>
                        <div className="contentBx">
                            <h5 className="title-h5">Paul Tang</h5>
                            <h6 className="title-h6">Co-founder & Principal AI Developer</h6>
                        </div>
                    </div>
                </Card>
            </CardGrid>
        </div>
    )
};

export default OurTeam;