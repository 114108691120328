import React from 'react';
import './styles.css';

const Header = () => (
  <header className='home-header'>
    <h1>
      <span></span> Latest updates <span></span>
    </h1>
    <p>
      to know more about our latest updates <br /> innovative and productive through
      weekly updates.
    </p>
  </header>
);

export default Header;
