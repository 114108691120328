import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Redirect} from 'react-router-dom'
import Home from './pages';
import SigninPage from './pages/signin';
import MotorTestsPage from './pages/motortests';
import OurTeamPage from './pages/ourteam';
import ArticlePage from './pages/articles';
import BlogHomePage from './pages/bloghome';
import BlogEventPage from './pages/blogevent';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <Home /> } exact />
        <Route path="/signin" element={ <SigninPage /> } exact />
        <Route path="/motortests" element={ <MotorTestsPage /> } exact />
        <Route path="/ourteam" element={ <OurTeamPage /> } exact />
        <Route path="/research" element={ <ArticlePage /> } exact />
        <Route path='/bloghome' element={ <BlogHomePage /> } exact />
        <Route path='/blogevent/:id' element={ <BlogEventPage /> } exact />

      </Routes>
    </Router>
  );
}

export default App;
