import React from 'react'
import OurTeam from '../components/OurTeam'
import NavbarWithoutTags from '../components/NavbarWithoutTags'
import Footer from '../components/Footer'

const OurTeamPage = () => {
  return (
    <>
      <NavbarWithoutTags />
      <OurTeam />
      <Footer />
    </>
  )
}

export default OurTeamPage