export const blogList = [
  {
    id: 1,
    title: 'Winning first place of the IntEr-HRI competition of IJCAI 23',
    category: 'Event',
    subCategory: ['Biomedical', 'Machine Learning', 'Cognition'],
    description:
      "We are pleased to announce that our team NeuroPrior AI are the first place winner of the offline stage of the IntEr-HRI competition of the 32nd International Joint Conference on Artificial Intelligence (IJCAI'23). ",
    description2:
      "The main goal of the challenge is to develop new competitive Signal Processing and/or Machine Learning (ML) approaches for the asynchronous detection of erroneous behaviors based on single-trial EEG analysis. We present a comparative analysis of two distinct machine-learning models designed to detect asynchronous errors during Human-Robot Interaction (HRI). The models under scrutiny are a customized ResNet model and an ensemble model, both trained and validated using EEG data. The ResNet model is a unique adaptation of the Residual Network, comprising a one-dimensional convolutional layer followed by batch normalization and ReLU activation. It also features a custom ResidualBlock structure and an adaptive average pooling layer, concluding with a fully connected linear layer for binary classification. The ensemble model, on the other hand, incorporates various machine learning models such as MLP, logistic regression, SVM, random forest, and XGBoost, unified in a pipeline with feature extraction and transformation steps. A critical component of our research is the innovative probability map, which maintains a granularity of 0.1 seconds. This map forecasts the likelihood of forthcoming one-second intervals being classified as either Error (S96) or Non-error (non-S96). Our comparative analysis reveals significant variations in the performance of the two models, both of which exhibit promising results in detecting erroneous behaviors during HRI. We provide detailed validation results, including the accuracy, F1 score, and confusion matrix for each model. This study offers valuable insights into the potential of machine learning in enhancing HRI efficiency and accuracy, indicating promising directions for future research. ",
    authorName: 'Chun Pang Wong (Paul)',
    authorAvatar: '/assets/images/Wong Chun Pang.jpg',
    createdAt: 'Aug 27, 2023',
    cover: '/assets/images/intEr-HRI.png',
    picture2: '/assets/images/intEr-HRI_cert.png',
    picture3: '/assets/images/intEr-HRI_result.png',
  }
  // ,
  // {
  //   id: 2,
  //   title: 'Milan Places That Highlight The City',
  //   category: 'Event',
  //   subCategory: ['Biomedical', 'Sensor', 'Cognition'],
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   authorName: 'Chun Pang Wong (Paul)',
  //   authorAvatar: '/assets/images/Wong Chun Pang.jpg',
  //   createdAt: 'May 27, 2022',
  //   cover: '/assets/images/f67396fc3cfce63a28e07ebb35d974ac.jpg',
  // },
  // {
  //   id: 3,
  //   title: 'Online Shopping – An Alternative to Shopping',
  //   category: 'Research',
  //   subCategory: ['Biomedical', 'Sensor', 'Cognition'],
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   authorName: 'Chun Pang Wong (Paul)',
  //   authorAvatar: '/assets/images/Wong Chun Pang.jpg',
  //   createdAt: 'May 27, 2022',
  //   cover: '/assets/images/fQwuyKJ9qxjSbr6REcgtmW-1200-80.jpg',
  // },
  // {
  //   id: 4,
  //   title: 'ADVENTURE IN YOU',
  //   category: 'Conference',
  //   subCategory: ['Biomedical', 'Sensor', 'Cognition'],
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   authorName: 'Chun Pang Wong (Paul)',
  //   authorAvatar: '/assets/images/Wong Chun Pang.jpg',
  //   createdAt: 'May 27, 2022',
  //   cover: '/assets/images/graphic-design-trends.png',
  // },
  // {
  //   id: 5,
  //   title: 'Loaded BBQ Baked Potatoes',
  //   category: 'Event',
  //   subCategory: ['Biomedical', 'Sensor', 'Cognition'],
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   authorName: 'Chun Pang Wong (Paul)',
  //   authorAvatar: '/assets/images/Wong Chun Pang.jpg',
  //   createdAt: 'May 27, 2022',
  //   cover: '/assets/images/make-it-personal.jpg',
  // },
  // {
  //   id: 6,
  //   title: 'Beyond the Beach',
  //   category: 'Research',
  //   subCategory: ['Biomedical', 'Sensor', 'Cognition'],
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   authorName: 'Chun Pang Wong (Paul)',
  //   authorAvatar: '/assets/images/Wong Chun Pang.jpg',
  //   createdAt: 'May 27, 2022',
  //   cover:
  //     '/assets/images/Purple-Combination-colors-graphic-design-predictions-1024x576-1024x576.jpg',
  // },
  // {
  //   id: 7,
  //   title: 'Art & Perception',
  //   category: 'Event',
  //   subCategory: ['Biomedical', 'Sensor', 'Cognition'],
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   authorName: 'Chun Pang Wong (Paul)',
  //   authorAvatar: '/assets/images/Wong Chun Pang.jpg',
  //   createdAt: 'May 27, 2022',
  //   cover: '/assets/images/Synthwave-Postmodern.jpg',
  // },
];
