import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { blogList } from '../config/data';
import Chip from '../components/common/Chip';
import EmptyList from '../components/common/EmptyList';
import './style.css';

export default function BlogEventSection() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        let blog = blogList.find((blog) => blog.id === parseInt(id));
        if (blog) {
            setBlog(blog);
        }
    }, [id]);


    return (
        <>
            {
                blog ? (
                    <div className='blog-wrap'>
                        <Link className='blog-goBack' to='/bloghome'>
                            <span> &#8592;</span> <span>Go Back</span>
                        </Link>
                        <header>
                            <p className='blog-date'>Published {blog.createdAt}</p>
                            <h1>{blog.title}</h1>
                            <div className='blog-subCategory'>
                                {blog.subCategory.map((category, i) => (
                                    <div key={i}>
                                        <Chip label={category} />
                                    </div>
                                ))}
                            </div>
                        </header>
                        <img src={blog.cover} alt='cover' />
                        <p className='blog-desc'>{blog.description}</p>
                        <img className='blog-picture' src={blog.picture2} alt='picture1' />
                        <p className='blog-desc-2'>{blog.description2}</p>
                        <img className='blog-picture' src={blog.picture3} alt='picture2' />

                    </div>
                ) : (
                    <EmptyList />
                )
            }
        </>
    )
}
