import React from 'react'
import NavbarWithoutTags from '../components/NavbarWithoutTags'
import Footer from '../components/Footer'
import BlogEventSection from '../components/BlogSection/BlogEventSection'

const BlogEventPage = () => {
  return (
    <>
      <NavbarWithoutTags />
      <BlogEventSection />
      <Footer />
    </>
  )
}

export default BlogEventPage