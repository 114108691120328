import React from 'react'
import NavbarWithoutTags from '../components/NavbarWithoutTags'
import Footer from '../components/Footer'
import BlogHomeSection from '../components/BlogSection/BlogHomeSection'

const BlogHomePage = () => {
  return (
    <>
      <NavbarWithoutTags />
      <BlogHomeSection />
      <Footer />
    </>
  )
}

export default BlogHomePage